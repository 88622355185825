<template>
  <div class="plan-add">
    <div class="header">
      <div class="left" @click="onGoBack">
        <img :src="goBackImg" />
      </div>
      <div class="center">
        作业系统
      </div>
      <div class="right"></div>
    </div>
    <div class="title-block">
      <div class="title">
        <img :src="homeWorkIcon" />
        <input type="text" class="title-input" v-model="req.title" placeholder="标题" />
        <img :src="editIcon" @click="onEdit" />
      </div>
    </div>
    <div class="main-block">
      <div class="main">
        <div class="course-block">
          <img class="select-course-icon" :src="selectCourseIcon" />
          <div class="select-course-title">
            {{ selectCourse ? selectCourse.courseName : "" }}
          </div>
        </div>
        <div class="text-block">
          <div class="plan-text">{{ req.workText }}</div>
        </div>
        <div class="upload-block">
          <aidaUpload
            v-model="req.userAttach"
            :readonly="true"
            :bizType="'user_home_work'"
            :isSingleFile="false"
            :maxSize="100"
          >
          </aidaUpload>
        </div>
        <div class="split-line"></div>
        <div class="text-block">
          <div class="plan-text" v-html="req.teacherText"></div>
        </div>
        <div class="upload-block" v-if="req.teacherAttach && req.teacherAttach.length > 0">
          <aidaUpload
            v-model="req.teacherAttach"
            :readonly="true"
            :bizType="'user_home_work'"
            :isSingleFile="false"
            :maxSize="100"
          >
          </aidaUpload>
        </div>
        <div class="audit-block">
          <div class="text-info">
            <div class="info" v-for="(item, index) in req.logList" :key="index">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userHomeWorkApi } from "@/api/userHomeWorkApi.js";
import { Toast, Notify } from "vant";
import aidaUpload from "@/components/aidaUpload.vue";
import dayjs from "dayjs";
import { matchFileType } from "@/util/validate";
export default {
  components: {
    aidaUpload,
  },
  data() {
    return {
      goBackImg: require("@/assets/images/go-back.png"),
      homeWorkIcon: require("@/assets/images/user-home-work/home-work.png"),
      selectCourseIcon: require("@/assets/images/user-home-work/select-course.png"),
      downArrowIcon: require("@/assets/images/user-home-work/down-arrow.png"),
      timeIcon: require("@/assets/images/user-learn-plan/date.png"),
      editIcon: require("@/assets/images/user-learn-plan/edit.png"),
      bizType: "home-work",
      id: 0,

      req: {
        id: 0,
        title: "",
        workText: "",
        startTime: 0,
        endTime: 0,
        hasReview: false,
        userAttach: [],
        teacherText: "",
        teacherAttach: [],
      },

      showFullCalendar: false,
      dateType: "",
      startDateStr: "2022-06-30",
      endDateStr: "2022-06-30",

      showHourCalendar: false,
      timeType: "",
      startTimeStr: "",
      endTimeStr: "",

      showCourseSelector: false,
      ownCourseList: [],
      selectCourse: null,
    };
  },
  methods: {
    async initDbData(id) {
      let [ret, err] = await userHomeWorkApi.awaitChain(userHomeWorkApi.get(id));
      console.log("#1", ret);
      if (ret && ret.code == 0 && ret.data) {
        this.wrapAttach(ret.data.userAttach);
        this.wrapAttach(ret.data.teacherAttach);
        this.req = ret.data;
        if (this.req && this.req.teacherText) {
          this.req.teacherText = this.req.teacherText.replaceAll("\n", "<br />");
        }
        this.epochToStr();
        this.wrapAudit(this.req.audit);
        console.log("#2", this.req);
      } else {
        Notify({
          type: "danger",
          message: "系统出错，请联系客服",
        });
      }
      [ret, err] = await userHomeWorkApi.awaitChain(userHomeWorkApi.userOwnCourse());
      // console.log("##", ret);
      if (ret && ret.code == 0) {
        let retList = [];
        for (let item of ret.datas) {
          let newObj = {
            courseId: item.courseId,
            courseName: item.courseName,
          };
          retList.push(newObj);
          if (item.courseId == this.req.courseId) {
            this.selectCourse = newObj;
          }
        }
        this.ownCourseList = retList;
      }
    },
    getOwnCourse() {
      userHomeWorkApi.userOwnCourse().then((ret) => {
        //console.log(ret);
        let { code, datas } = ret;
        if (code == 0 && datas) {
          let retList = [];
          for (let item of datas) {
            retList.push({
              courseId: item.courseId,
              courseName: item.courseName,
            });
          }
          this.ownCourseList = retList;
        }
      });
    },
    wrapAudit(auditList) {
      let logList = [];
      for (let i = auditList.length - 1; i >= 0; i--) {
        const day = dayjs(Number(auditList[i].actTime));
        const dayStr = day.format("YYYY.MM.DD");
        logList.push(dayStr + " " + auditList[i].actLog + "作业");
      }
      this.req.logList = logList;
    },
    wrapAttach(itemList) {
      for (let item of itemList) {
        if (item.fileType) {
          continue;
        }
        item.fileType = matchFileType(item.key);
      }
    },
    onGoBack() {
      //this.$router.go(-1);
      this.$router.push({
        path: "/userHomeWork",
      });
    },
    onEdit() {
      this.$router.push({
        path: "/userHomeWork/addEdit",
        query: {
          id: this.id,
        },
      });
    },
    strToEpoch() {
      let startTime = dayjs(this.startDateStr + " " + this.startTimeStr, "YYYY-MM-DD HH:mm");
      let endTime = dayjs(this.endDateStr + " " + this.endTimeStr, "YYYY-MM-DD HH:mm");
      //console.log(startTime, startTime.valueOf(), endTime.valueOf());
      this.req.startTime = startTime.valueOf();
      this.req.endTime = endTime.valueOf();
    },
    epochToStr() {
      const startDayjs = dayjs(Number(this.req.startTime));
      const endDayjs = dayjs(Number(this.req.endTime));
      this.startDateStr = startDayjs.format("YYYY-MM-DD");
      this.startTimeStr = startDayjs.format("HH") + ":00";

      this.endDateStr = endDayjs.format("YYYY-MM-DD");
      this.endTimeStr = endDayjs.format("HH") + ":00";
    },
  },
  mounted() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.initDbData(this.id);
    } else {
      Notify({
        type: "warning",
        message: "缺少id参数",
      });
    }
  },
  destroyed() {},
  filters: {},
  watch: {},
};
</script>

<style lang="less" scoped>
.plan-add {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  .header {
    height: 62px;
    flex: 0 0 auto;
    background: #ffffff;
    display: flex;
    flex-direction: row;
    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 27px;
      img {
        margin-left: 16px;
        height: 23px;
        width: auto;
      }
    }
    .center {
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 20px;
      font-weight: 500;
      color: #323233;
      letter-spacing: 0.44px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 10px;
    }
    .right {
      width: 27px;
    }
  }
  .title-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .title {
      width: 344px;
      height: 44px;
      background: #ffffff;
      border-radius: 8px;
      margin: 14px 0px 0px 0px;
      display: flex;
      flex-direction: row;
      img {
        height: 16px;
        width: 16px;
        margin: 14px 12px 10px 12px;
      }
      .title-input {
        width: 300px;
        font-size: 16px;
        color: #c3c3c3;
        letter-spacing: 0.36px;
        border: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .main-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    .main {
      background: #ffffff;
      border-radius: 8px;
      width: 344px;
      margin: 6px 0px 0px 0px;
      display: flex;
      flex-direction: column;
      .course-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        //justify-content: space-between;
        height: 42px;
        margin: 0 12px;
        border-bottom: 1px solid rgb(242, 242, 242);
        .select-course-title {
          font-size: 16px;
          color: #505051;
          letter-spacing: 0.36px;
          width: 270px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 0px 0px 0px 12px;
        }
        .select-course-icon {
          height: 16px;
          width: 16px;
        }
        .select-course-expand-icon {
          height: 8px;
          width: 14px;
        }
      }
      .datetime-block {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 42px;
        font-size: 16px;
        margin: 0 12px;
        color: #505051;
        letter-spacing: 0.36px;
        border-bottom: 1px solid rgb(242, 242, 242);
        .left {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 16px;
          color: #505051;
          letter-spacing: 0.36px;
          img {
            height: 16px;
            width: 16px;
            margin: 12px 12px 12px 0px;
          }
        }
        .right {
          display: flex;
          flex-direction: row;
          align-items: center;
          .calendar-val {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 96px;
            background: #efefef;
            border-radius: 8px;
            font-size: 14px;
            color: #323233;
            letter-spacing: 0.31px;
            margin: 0px 4px 0px 0px;
          }
          .time-val {
            width: 50px;
          }
        }
      }
      .text-block {
        margin: 14px 0px 0px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .plan-text {
          height: 132px;
          width: 318px;
          font-size: 14px;
          color: #c3c3c3;
          letter-spacing: 0.31px;
          overflow: hidden;
          overflow-y: scroll;
          border: none;
          resize: none;
        }
      }
      .upload-block {
        margin: 12px;
      }
      .split-line {
        margin: 0px 12px;
        height: 1px;
        border-bottom: 1px solid rgb(242, 242, 242);
      }
    }
  }
  .audit-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    background: #f3f5f7;
    .text-info {
      width: 344px;
      margin: 40px 0px 0px 0px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      .info {
        font-size: 11px;
        color: #9298a4;
        letter-spacing: 0.24px;
        margin: 0px 0px 18px 0px;
      }
    }
  }
}
</style>
